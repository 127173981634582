import * as React from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  Paper,
  CardContent,
  Button,
  IconButton,
  Avatar,
  Modal,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import userImg from "../../assets/images/user.jpg";
import botImg from "../../assets/images/chef.png";
import FillUser from "../../assets/icons/filluserview.svg";
import { ButtonComponent } from "../../components/form_components/button";
import { db } from "../../components/firebase-config";
import {
  collection,
  // addDoc,
  setDoc,
  query,
  // orderBy,
  // onSnapshot,
  getDocs,
  // getDoc,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";
import { SpanStyle } from "../../styles/common_styles";
import { useLocation } from "react-router-dom";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
const Message = ({ message, position, status, roleId, roleDetails }) => {
  const ref = React.useRef();
  const location = useLocation();
  const { ticketStatus } = location.state || {};
  const { ticketUUId } = location.state || {};
  // const { AssignStatus } = location.state || {};
  const timestampInSeconds = message?.createdAt?.seconds;
  const [ManagerReason, setManagerReason] = React.useState("");
  const [internalAssign, setInternalAssign] = React.useState(false);
  const [internalTicketStatus, setInternalTicketStatus] = React.useState(false);
  const [assignedRoles, setAssignedRoles] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [error] = React.useState("");
  const milliseconds = timestampInSeconds * 1000;
  const date = new Date(milliseconds);
  // const { userName } = location.state || {};
  const [currency, setCurrency] = React.useState("");
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);

  const shouldShowAssignButton = () => {
    if (ticketStatus === 2) {
      return false; // Hide if ticketStatus is 2
    }
    if (roleId === 2) {
      return false; // Hide if roleId is 2
    }
    if (roleId === 5 || roleId === 6) {
      return true; // Always show if roleId is 5 or 6
    }
    if (
      internalAssign === true &&
      (roleId === 3 || roleId === 4) &&
      roleId === lastElement
    ) {
      return true; // Show if AssignStatus is true and roleId is 3 or 4
    }
    return false; // Hide in all other cases
  };

  const generateDateString = () => {
    // Get current date and time
    let currentDate = new Date();
    let year = String(currentDate.getFullYear()).padStart(4, "0"); // 4 digits
    let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 2 digits (January is 0)
    let day = String(currentDate.getDate()).padStart(2, "0"); // 2 digits
    let hours = String(currentDate.getHours()).padStart(2, "0"); // 2 digits
    let minutes = String(currentDate.getMinutes()).padStart(2, "0"); // 2 digits
    let seconds = String(currentDate.getSeconds()).padStart(2, "0"); // 2 digits
    let milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0"); // 3 digits

    // Concatenate components and calculate length
    let dateString =
      year + month + day + hours + minutes + seconds + milliseconds;
    // let length = dateString.length;
    return dateString;
  };

  React.useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const handleDeclineClose = () => {
    setOpen(false);
  };

  const handleStatusSubmit = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "ticketConversations_stage"),
          where("messageId", "==", message.messageId)
        )
      );
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          ApprovalData: {
            RefundType: message.ApprovalData.RefundType || "",
            CSRDesc: message.ApprovalData.CSRDesc || "",
            amount: message.ApprovalData.amount || "",
            status: "Declined",
            reason: ManagerReason || "",
          },
          userCanSee: false,
          assignStatus: true,
        });
        handleDeclineClose();
        setManagerReason("");
        //declineTicket API
        const payload = {
          ticketId: ticketUUId,
        };
        const rejectTicketResponse = await ManagementServices.declineTicket(
          payload
        );
        if (rejectTicketResponse?.status === 1) {
          setAlrtMsg(rejectTicketResponse?.message);
          setSuccessAlrtOpen(true);
        } else {
          setAlrtMsg(rejectTicketResponse?.message);
          setErrorAlrtOpen(true);
        }
      } else {
        console.log("No message found for the provided ticket ID");
      }
    } catch (error) {
      console.error("Error updating message and declining ticket: ", error);
    }
  };

  // Admin Decline approval functionality
  const declineModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleDeclineClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 250,
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Grid item md={11} mt={2}>
            <FormControl fullWidth>
              <Grid mb={2}>Reason for Decline</Grid>
              <TextareaAutosize
                value={ManagerReason}
                onChange={(e) => setManagerReason(e.target.value)}
                className="textArea"
                aria-label="Description"
                placeholder="Enter your Reason for Decline"
              />
            </FormControl>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Grid sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <ButtonComponent
                btnText="submit"
                onClick={() => handleStatusSubmit(message)}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

  const handleApproveStatus = async (message) => {
    const messageId = generateDateString();
    try {
      // Create a reference to the document with the custom ID
      const docRef = doc(
        collection(db, "ticketConversations_stage"),
        message.messageId
      );
      // Add the document with the custom ID and other data
      await setDoc(docRef, {
        createdAt: new Date(),
        ticketId: message.ticketId,
        text: "",
        senderType: 3,
        userCanSee: true,
        messageId: message.messageId,
        ApprovalData: {
          RefundType: message.ApprovalData.RefundType || "",
          CSRDesc: message.ApprovalData.CSRDesc || "",
          amount: message.ApprovalData.amount || "",
          status: "Approved",
          reason: "",
        },
        assignStatus: true,
      });
      // Call the approveTicket API
      const payload = {
        ticketId: ticketUUId,
      };
      const approveTicketResponse = await ManagementServices.approveTicket(
        payload
      );
      if (approveTicketResponse.status === 1) {
        setSuccessAlrtOpen(true);
        setAlrtMsg(approveTicketResponse.message);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(approveTicketResponse.message);
      }
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const handleAssignStatus = async (message) => {
    // const messageId = generateDateString();
    try {
      // Create a reference to the document with the custom ID
      const docRef = doc(
        collection(db, "ticketConversations_stage"),
        message.messageId
      );
      // Add the document with the custom ID and other data
      await setDoc(docRef, {
        createdAt: new Date(),
        ticketId: message.ticketId,
        text: "",
        senderType: 3,
        userCanSee: true,
        messageId: message.messageId,
        ApprovalData: {
          RefundType: message.ApprovalData.RefundType || "",
          CSRDesc: message.ApprovalData.CSRDesc || "",
          amount: message.ApprovalData.amount || "",
          status: "Waiting for approval",
          reason: "",
        },
        assignStatus: true,
        roleId: roleId,
      });
      // Call the approveTicket API
      const payload = {
        ticketId: ticketUUId,
      };
      const AssignTicketResponse = await ManagementServices.ticketAssign(
        payload
      );
      if (AssignTicketResponse.status === 1) {
        setSuccessAlrtOpen(true);
        setAlrtMsg(AssignTicketResponse.message);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(AssignTicketResponse.message);
      }
      getTicketAssignStatus();
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };
  // Format the date and time
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  //Functionality for getting Admin details for the message
  const renderSenderDetails = (
    senderId,
    senderDetails,
    position,
    name,
    type
  ) => {
    if (position === "left") {
      return (
        <Box>
          <Typography variant="caption" color="black" fontWeight={700}>
            {name}-{type === 1 ? "Chef" : "Consumer"}
          </Typography>
        </Box>
      );
    } else {
      const matchedSender = senderDetails.find(
        (sender) => sender?.id === senderId
      );
      if (matchedSender) {
        return (
          <Box>
            <Typography variant="caption" color="white" fontWeight={600}>
              {`${matchedSender.first_name} - ${matchedSender.role_name}`}
            </Typography>
          </Box>
        );
      } else {
        return null;
      }
    }
  };

  const getTicketAssignStatus = async () => {
    const payload = {
      ticketId: ticketUUId,
    };
    const ticketAssignStatusResponse =
      await ManagementServices.getTicketStatusUpdate(payload);
    if (ticketAssignStatusResponse?.status === 1) {
      setInternalAssign(ticketAssignStatusResponse?.data.isAssigned);
      setAssignedRoles(ticketAssignStatusResponse?.data.assigned_roles);
      setCurrency(ticketAssignStatusResponse?.data.currency);
    }
  };
  let lastElement = assignedRoles?.slice(-1)[0];

  React.useEffect(() => {
    getTicketAssignStatus();
  }, []);

  const formattedTime = date.toLocaleString("en-US", options);

  const isBot = message?.type === "Consumer";
  const avatarImg = isBot ? userImg : botImg;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent:
            position === "left" && !message?.assignStatus
              ? "flex-start"
              : position === "right" && !message?.assignStatus
              ? "flex-end"
              : "center",
          mb: 2,
        }}
      >
        <Grid ref={ref} sx={{ flexDirection: "column" }}>
          {position === "left" && (
            <Avatar src={avatarImg} alt={message?.type} sx={{ mr: 3 }} />
          )}
          {isBot && (
            <Typography variant="caption" color="textSecondary">
              {message?.userId}
            </Typography>
          )}
        </Grid>
        <Grid sx={{ flexDirection: "column" }}>
          {!message?.assignStatus ? (
            <Paper
              variant="outlined"
              sx={{
                p: 1,
                backgroundColor: position === "left" ? "#fff" : "#000",
                color: position === "left" ? "#000" : "#fff",
                maxWidth: "450px",
                borderRadius: "10px",
              }}
            >
              <Typography></Typography>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  maxWidth: "380px",
                  flexWrap: "wrap",
                  position: "relative",
                  paddingRight: "40px",
                }}
              >
                {renderSenderDetails(
                  message.senderId,
                  roleDetails,
                  position,
                  message.senderName,
                  message.senderType
                )}
                {message?.text}
                {message.userCanSee ? (
                  <IconButton
                    sx={{ position: "absolute", top: "-7px", right: "0px" }}
                  >
                    <img src={FillUser} alt="edit" />
                  </IconButton>
                ) : null}
              </Typography>
            </Paper>
          ) : (
            <Grid
              container
              sx={{
                display: "flex ",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card sx={{ width: 550, boxShadow: 10 }}>
                <CardContent>
                  <Grid
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        textAlign: "start",
                        fontWeight: "bold",
                      }}
                      color="#000"
                      gutterBottom
                    >
                      Approval Needed
                    </Typography>
                    {shouldShowAssignButton() &&
                      message?.ApprovalData.status !== "Declined" &&
                      message?.ApprovalData.status !== "Approved" && (
                        <>
                          <ButtonComponent
                            btnText="Assign"
                            onClick={() => handleAssignStatus(message)}
                          />
                        </>
                      )}
                  </Grid>
                </CardContent>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pl: 2,
                    pb: 2,
                  }}
                >
                  <Grid item md={4} sx={SpanStyle} mb={2}>
                    Refund Type
                  </Grid>
                  <Grid item md={2}>
                    :
                  </Grid>
                  <Grid item md={6} mb={2}>
                    {message?.ApprovalData.RefundType === 1
                      ? "Store Credit"
                      : "Refund"}
                  </Grid>
                  <Grid item md={4} sx={SpanStyle}>
                    CSR Description
                  </Grid>
                  <Grid item md={2}>
                    :
                  </Grid>
                  <Grid item md={6}>
                    {message?.ApprovalData.CSRDesc}
                  </Grid>
                  <Grid item md={4} pt={2} sx={SpanStyle}>
                    Reward/Store Credit Amount
                  </Grid>
                  <Grid item md={2} pt={2}>
                    :
                  </Grid>
                  <Grid item md={6} pt={2}>
                    {message?.ApprovalData.amount} {currency || "/-"}
                  </Grid>
                  <Grid item md={4} pt={2} sx={SpanStyle}>
                    Status
                  </Grid>
                  <Grid item md={2} pt={2}>
                    :
                  </Grid>
                  <Grid
                    item
                    md={6}
                    pt={2}
                    sx={{
                      color:
                        message?.ApprovalData.status === "Approved"
                          ? "green"
                          : message?.ApprovalData.status === "Declined"
                          ? "red"
                          : message?.ApprovalData.status ===
                            "Waiting for approval"
                          ? "orange"
                          : "inherit",
                      fontWeight: "bold",
                    }}
                  >
                    {message?.ApprovalData.status || ""}
                  </Grid>
                  {message?.ApprovalData.reason && (
                    <>
                      <Grid item md={4} pt={2} sx={SpanStyle}>
                        Admin Reason
                      </Grid>
                      <Grid item md={2} pt={2}>
                        :
                      </Grid>
                      <Grid
                        item
                        md={6}
                        pt={2}
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          maxWidth: "380px",
                          flexWrap: "wrap",
                          position: "relative",
                        }}
                      >
                        {message?.ApprovalData.reason}
                      </Grid>
                    </>
                  )}
                  {message?.ApprovalData.status !== "Approved" &&
                  message?.ApprovalData.status !== "Declined" &&
                  roleId !== 6 &&
                  roleId === lastElement &&
                  internalAssign === true &&
                  roleId !== 5 ? (
                    <>
                      <Grid
                        item
                        md={6}
                        mt={2}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{
                            width: "80%",
                            color: "white",
                            backgroundColor: "green",
                            borderRadius: "40px",
                            "&:hover": {
                              backgroundColor: "green",
                              color: "white",
                            },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => handleApproveStatus(message)}
                        >
                          Approve
                        </Button>
                      </Grid>
                      {roleId !== 6 && (
                        <Grid item md={6} mt={2}>
                          <Button
                            sx={{
                              width: "80%",
                              color: "white",
                              backgroundColor: "red",
                              borderRadius: "40px",
                              "&:hover": {
                                backgroundColor: "red",
                                color: "white",
                              },
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => setOpen(true)}
                          >
                            Decline
                          </Button>
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
              </Card>
            </Grid>
          )}
          <Box>
            {!message?.assignStatus && (
              <Typography variant="caption" color="textSecondary">
                {formattedTime}
              </Typography>
            )}
          </Box>
        </Grid>
        {position === "right" && !message?.assignStatus && (
          <Avatar src={avatarImg} alt={message?.sender} sx={{ ml: 3 }} />
        )}
      </Box>
      {declineModal()}
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default Message;
