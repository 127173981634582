import * as React from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  // Avatar,
  Modal,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  TextareaAutosize,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Vector from "../../assets/icons/Vector.svg";
import strokeUser from "../../assets/icons/strokeuserview.svg";
import { ButtonComponent } from "../../components/form_components/button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { db } from "../../components/firebase-config";
import {
  collection,
  // addDoc,
  // setDoc,
  query,
  orderBy,
  onSnapshot,
  getDocs,
  getDoc,
  // updateDoc,
  doc,
  where,
} from "firebase/firestore";
import { ButtonStyle, ButtonStyle1 } from "../../styles/common_styles";
import { useLocation } from "react-router-dom";
import { GetAdminDetails } from "../../utils/helpers";
import { ManagementServices } from "../../services/management_services";
import Message from "./message";
import { FirebaseServices } from "../../utils/firebasefunctions";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";

const Chat = () => {
  const location = useLocation();
  const ticketId = location?.state?.ticket_id;
  const { ticketUUId } = location.state || {};
  const { ticketStatus } = location.state || {};
  const { value } = location.state || {};
  const { receiverId } = location.state || {};
  const { orderId } = location.state || {};
  const [message, setMessages] = React.useState([]);
  const [input, setInput] = React.useState("");
  const [openModal, setOpenmodal] = React.useState(false);
  const [role, setRole] = React.useState("");
  const [viewstatus, setViewStatus] = React.useState(false);
  const [roleId, setRoledId] = React.useState("");
  const [memberIds, setMemberIds] = React.useState([]);
  const [rolrUUId, setRoleUUId] = React.useState("");
  const [roleDetails, setRoleDetails] = React.useState([]);
  // const { AssignStatus } = location.state || {};
  const [internalAssign, setInternalAssign] = React.useState(false);
  const [assignedRoles, setAssignedRoles] = React.useState([]);
  const [internalTicketStatus, setInternalTicketStatus] = React.useState(false);
  const [currency, setCurrency] = React.useState("");
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Adding the memberIds to the ticket functionality
  const createAdmins = async (id) => {
    try {
      const docRef = doc(db, "tickets_stage", id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (data && data.memberIds && data.memberIds.length > 0) {
          let updatedMemberIds = [...data.memberIds];
          const existingIndex = updatedMemberIds.indexOf(rolrUUId);
          if (existingIndex === -1) {
            if (!rolrUUId === "") {
              updatedMemberIds.push(rolrUUId);
            }
          } else {
            updatedMemberIds[existingIndex] = rolrUUId;
          }
          const memberIds = { memberIds: updatedMemberIds };
          await FirebaseServices.updateMemberIdsSetDoc(docRef, memberIds);
          setMemberIds(updatedMemberIds);
        } else {
          const latest = [rolrUUId];
          const latestMemberIds = { memberIds: latest };
          await FirebaseServices.updateLatestMemberIdsSetDoc(
            docRef,
            latestMemberIds
          );
          setMemberIds(latest);
        }
      } else {
        const latest = [rolrUUId];
        const latestMemberIdss = { memberIds: latest };
        await FirebaseServices.updatenotMemberIdsSetDoc(
          docRef,
          latestMemberIdss
        );
        setMemberIds(latest);
      }
    } catch (error) {
      console.error("Error fetching members: ", error);
    }
  };

  const fetchCollections = async (ticketId) => {
    try {
      // Create a reference to the collection
      const collectionRef = collection(db, "ticketConversations_stage");
      // Construct a query to filter documents by ticketId
      const querySnapshot = onSnapshot(
        query(
          collectionRef,
          where("ticketId", "==", ticketId),
          orderBy("createdAt", "asc")
        ),
        (snapshot) => {
          const fetchMessages = [];
          snapshot.forEach((doc) => {
            fetchMessages.push(doc.data());
          });
          setMessages(fetchMessages);
        }
      );
    } catch (error) {
      console.error("Error fetching messages: ", error);
      throw error;
    }
  };

  const onSubmit = async (data) => {
    const messageId = generateDateString();
    try {
      const docRef = doc(
        collection(db, "ticketConversations_stage"),
        messageId
      );
      const messageData = {
        createdAt: new Date(),
        ticketId: ticketId,
        text: input,
        senderType: 3,
        userCanSee: false,
        ApprovalData: {
          RefundType: data.type,
          CSRDesc: data.description,
          amount: data.amount,
          status: "Waiting for approval",
          reason: "",
        },
        messageId: messageId,
        assignStatus: true,
      };
      await FirebaseServices.assignTicketDoc(docRef, messageData);
      handleClose();
      const payload = {
        ticketId: ticketUUId,
        rewardType: data.type,
        rewardAmount: data.amount,
      };
      const AssignTicketResponse = await ManagementServices.ticketAssign(
        payload
      );
      if (AssignTicketResponse?.status === 1) {
        setAlrtMsg(AssignTicketResponse?.message);
        setSuccessAlrtOpen(true);
      } else {
        setAlrtMsg(AssignTicketResponse?.message);
        setErrorAlrtOpen(true);
      }
      getTicketAssignStatus();
      setInput("");
      setViewStatus(false);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
    reset();
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const getAdminDetailsforMessages = async () => {
    const payload = {
      userIds: memberIds,
    };
    const adminDetailsResponse =
      await ManagementServices.getroleDetailsforMessages(payload);
    if (adminDetailsResponse?.status === 1) {
      setRoleDetails(adminDetailsResponse?.data);
    }
  };

  const getTicketAssignStatus = async () => {
    const payload = {
      ticketId: ticketUUId,
    };
    const ticketAssignStatusResponse =
      await ManagementServices.getTicketStatusUpdate(payload);
    if (ticketAssignStatusResponse?.status === 1) {
      setInternalAssign(ticketAssignStatusResponse?.data.isAssigned);
      setAssignedRoles(ticketAssignStatusResponse?.data.assigned_roles);
      setInternalTicketStatus(ticketAssignStatusResponse?.data.status);
      setCurrency(ticketAssignStatusResponse?.data.currency);
    }
  };

  React.useEffect(() => {
    getTicketAssignStatus();
  }, [internalAssign, internalTicketStatus]);

  React.useEffect(() => {
    try {
      fetchCollections(ticketId);
      createAdmins(ticketId);
    } catch (error) {
      // Handle error
    }
  }, [ticketId, rolrUUId]);

  const getAdminDetails = async () => {
    const AdminDetails = await GetAdminDetails();
    setRole(AdminDetails.role_name);
    setRoledId(AdminDetails.role_id);
    setRoleUUId(AdminDetails.id);
  };

  React.useEffect(() => {
    getAdminDetails();
  }, []);

  React.useEffect(() => {
    getAdminDetailsforMessages();
  }, [memberIds]);

  const changeOpenModal = () => {
    setOpenmodal(true);
  };
  const handleClose = () => {
    setOpenmodal(false);
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const generateDateString = () => {
    // Get current date and time
    let currentDate = new Date();

    // Extract date and time components
    let year = String(currentDate.getFullYear()).padStart(4, "0"); // 4 digits
    let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 2 digits (January is 0)
    let day = String(currentDate.getDate()).padStart(2, "0"); // 2 digits
    let hours = String(currentDate.getHours()).padStart(2, "0"); // 2 digits
    let minutes = String(currentDate.getMinutes()).padStart(2, "0"); // 2 digits
    let seconds = String(currentDate.getSeconds()).padStart(2, "0"); // 2 digits
    let milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0"); // 3 digits

    // Concatenate components and calculate length
    let dateString =
      year + month + day + hours + minutes + seconds + milliseconds;
    return dateString;
  };

  const lastMessageRef = React.useRef();

  React.useEffect(() => {
    // Scroll to the last message when messages change
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [message]);

  // message sending functinality
  const sendMessage = async () => {
    if (input.trim() === "") return;
    const messageId = generateDateString();
    try {
      // Create a reference to the document with the custom ID
      const docRef = doc(
        collection(db, "ticketConversations_stage"),
        messageId
      );
      const messageData = {
        createdAt: new Date(),
        ticketId: ticketId,
        text: input,
        senderType: 3,
        senderId: rolrUUId,
        senderName: role,
        sentFrom: "",
        seenUser: [],
        deliverdUser: [],
        orderId: orderId || "",
        chatType: "ticket",
        messageViewType: 3,
        memebrIds: memberIds,
        viewStatus: viewstatus,
        messageId: messageId,
        ApprovalData: {},
        userCanSee: viewstatus,
        receiverType: parseInt(value),
        receiverId: receiverId,
      };
      await FirebaseServices.setMessageDoc(docRef, messageData);
      setInput("");
      const querySnapshot = await getDocs(
        query(
          collection(db, "userbadge_stage"),
          where(`ticketBadge.${ticketId}`, "!=", null)
        )
      );
      if (!querySnapshot.empty && viewstatus) {
        querySnapshot.forEach(async (doc) => {
          const badgeDocRef = doc.ref;
          const ticketBadge = doc.data().ticketBadge;
          const updatedCount = (ticketBadge[ticketId] || 0) + 1; // Increment badge count by 1
          // Update the badge count for the specific ticketId

          const badgeCountData = {
            ticketBadge: {
              ...ticketBadge,
              [ticketId]: updatedCount,
              [`${ticketId}_Web`]: updatedCount,
            },
          };
          await FirebaseServices.setUpdateBadgeCountDoc(
            badgeDocRef,
            badgeCountData
          );
        });
      } else {
        const senderDocRef = doc(db, "userbadge_stage", message[0].senderId);
        const ticketBadge = {
          ticketBadge: {
            [ticketId]: 1,
            [`${ticketId}_Web`]: 1,
          },
        };
        await FirebaseServices.setNotUpdateBadgeCountDoc(
          senderDocRef,
          ticketBadge
        );
      }
      getTicketAssignStatus();
      setViewStatus(false);
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const handleTicketClose = async () => {
    const payload = {
      ticketId: ticketUUId,
    };
    const closeTicketResponse = await ManagementServices.closeTicket(payload);
    if (closeTicketResponse.status === 1) {
      window.history.back();
    } else {
      console.error("Failed to close the ticket:", closeTicketResponse.error);
    }
  };

  // CSR Assigning Ticket to his Supieriors
  const assignModal = () => {
    return (
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: "fit-content",
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {value === "2" && (
                  <Grid item md={6} pr={1}>
                    <Grid mb={2}>Type</Grid>
                    <FormControl fullWidth>
                      <Select
                        {...register("type", { required: true })}
                        size="small"
                        inputProps={{ "aria-label": "Type" }}
                        defaultValue={null}
                      >
                        <MenuItem value={null}>Select</MenuItem>
                        {value === "2" && (
                          <MenuItem value={1}>Store Credit</MenuItem>
                        )}
                        <MenuItem value={2}>Refund</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.type && (
                      <p style={{ color: "red" }}>Type is required</p>
                    )}
                  </Grid>
                )}
                {value === "2" && (
                  <Grid item md={5}>
                    <Grid mb={2}>Amount</Grid>
                    <FormControl fullWidth>
                      <TextField
                        {...register("amount", {
                          required: true,
                          pattern: {
                            value: /^\d+(\.\d+)?$/,
                            message: "Amount should only contain numbers",
                          },
                        })}
                        size="small"
                        placeholder="Enter Amount"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {currency || "/-"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    {errors.amount && (
                      <p style={{ color: "red" }}>Amount is required</p>
                    )}
                  </Grid>
                )}
                <Grid item md={11} mt={2}>
                  <FormControl fullWidth>
                    <Grid mb={2}>Description</Grid>
                    <TextareaAutosize
                      {...register("description", { required: true })}
                      className="textArea"
                      aria-label="Description"
                      placeholder="Enter your description"
                    />
                  </FormControl>
                  {errors.description && (
                    <p style={{ color: "red" }}>Description is required</p>
                  )}
                </Grid>
                <Grid item mt={2} mb={2}>
                  <ButtonComponent btnText="Submit" type="submit" />
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    );
  };

  const shouldShowAssignButton = () => {
    if (internalTicketStatus === 2 || internalTicketStatus === 1) {
      return false; // Hide if ticketStatus is 2
    }
    if (roleId === 2) {
      return false; // Hide if roleId is 2
    }
    if (roleId === 5 || roleId === 6) {
      return true; // Always show if roleId is 5 or 6
    }
    if (internalAssign === true && (roleId === 3 || roleId === 4)) {
      return true; // Show if AssignStatus is true and roleId is 3 or 4
    }
    return false; // Hide in all other cases
  };

  return (
    <Box
      sx={{
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
      }}
    >
      <Grid
        sx={{ color: "black", m: 2, display: "flex", justifyContent: "start" }}
      >
        <IconButton
          sx={{ color: "black" }}
          onClick={() => window.history.back()}
        >
          <ArrowBackIcon />
        </IconButton>
        <Grid m={2} sx={{ fontWeight: "bold", fontSize: "30px" }}>
          {" "}
          {ticketId}
          {ticketStatus === 3 ? "(Reopen)" : ""}
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
        {message?.map((messages, index) => (
          <Message
            key={messages?.userId}
            message={messages}
            position={
              messages?.senderType === 1 || messages?.senderType === 2
                ? "left"
                : "right"
            }
            status={messages?.viewStatus}
            roleId={roleId}
            ref={lastMessageRef}
            roleDetails={roleDetails}
          />
        ))}
      </Box>
      <Box sx={{ p: 2, backgroundColor: "background.default" }}>
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="large"
              placeholder="Type a message..."
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              disabled={
                internalTicketStatus === 2 ||
                (internalAssign === false && !(roleId === 5 || roleId === 6))
                  ? true
                  : false
              }
              InputProps={{
                endAdornment: (
                  <>
                    {viewstatus ? (
                      <IconButton onClick={() => setViewStatus(false)}>
                        <img src={Vector} alt="edit" />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setViewStatus(true)}>
                        <img src={strokeUser} alt="edit" />
                      </IconButton>
                    )}
                    {internalTicketStatus !== 2 ? (
                      <SendIcon
                        color="black"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          sendMessage();
                        }}
                      />
                    ) : null}
                  </>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            item
            justifyContent="space-around"
            alignItems="center"
          >
            {internalTicketStatus !== 2 && (
              <Grid item>
                <Button sx={ButtonStyle} onClick={handleTicketClose}>
                  Close Ticket
                </Button>
              </Grid>
            )}
            {internalTicketStatus === 2 ? (
              <Grid
                sx={{
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                This Ticket is Closed
              </Grid>
            ) : (
              <>
                {shouldShowAssignButton() && internalTicketStatus !== 1 && (
                  <>
                    <Grid item>
                      <Button sx={ButtonStyle1} onClick={changeOpenModal}>
                        Assign
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {assignModal()}
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Box>
    </Box>
  );
};

export default Chat;
